.sigin-container {
  background-color: white;
  height: calc(100% - 70px);
  header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;
    img {
      width: 200px;
    }
    h2 {
      font-weight: 100;
      font-size: 32pt;
      text-align: center;
      margin-bottom: 32px;
    }
  }
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      padding: 16px;
      background-color: whitesmoke;
      button {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }
}
